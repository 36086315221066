import { addToast, Button, Input, Label, LinkButton, TextField } from '@troon/ui';
import { Show, createSignal, useContext, createMemo } from 'solid-js';
import { isServer } from 'solid-js/web';
import { useTrackEvent } from '@troon/analytics';
import { twJoin } from '@troon/tailwind-preset/merge';
import { IconCopy } from '@troon/icons/copy';
import { IconShare } from '@troon/icons/share';
import { IconCaretDownMd } from '@troon/icons/caret-down-md';
import { ReservationCtx } from '../../../../providers/reservation';
import { dayTimeToDate, formatDateTime } from '../../../../modules/date-formatting';
import { RecentPlayers } from './recent-players';

type Props = {
	showRecentInvited?: boolean;
};

export function InvitePlayers(props: Props) {
	const data = useContext(ReservationCtx)!;
	const trackEvent = useTrackEvent();
	const [showRecent, setShowRecent] = createSignal(false);

	const shareData = createMemo(() => {
		const dayTime = data()?.reservation.dayTime;
		const shareData = {
			title: 'Join my tee time',
			url: data()?.reservation.shareInviteUrl ?? undefined,
			text: `I’ve booked a tee time for ${data()?.reservation.playerCount} at ${data()?.reservation.facility.name} on ${dayTime ? formatDateTime(dayTimeToDate(dayTime)) : ''}.`,
		};
		if (isServer) {
			return shareData;
		}

		return (
			typeof window !== 'undefined' &&
			typeof window.navigator.share === 'function' &&
			window.navigator.canShare(shareData) &&
			shareData
		);
	});

	return (
		<div class="flex flex-col gap-4 md:gap-8">
			<div>
				<div class="flex flex-row items-end gap-2">
					<TextField name="url" class="shrink grow">
						<Label class="sr-only">Copy & share your unique link</Label>
						<Input
							readonly
							value={data()?.reservation.shareInviteUrl ?? ''}
							onClick={(event) => {
								event.currentTarget.select();
							}}
						/>
					</TextField>
					<Button
						type="button"
						onClick={() => {
							try {
								navigator.clipboard.writeText(data()?.reservation.shareInviteUrl ?? '');
								addToast({ title: 'Invite URL copied to clipboard', variant: 'positive' });
							} catch {
								// nothing
							}
						}}
						class="size-12 shrink-0 grow-0 p-0 text-lg"
					>
						<IconCopy title="Copy URL" />
					</Button>
				</div>
			</div>

			<div class="-ms-2 flex justify-start gap-4">
				<Show when={shareData()}>
					{(shareData) => (
						<LinkButton
							appearance="primary"
							class="font-medium"
							onClick={() => {
								const trackData = { reservationId: data()?.reservation.id };
								navigator
									.share(shareData())
									.then(() => {
										trackEvent('inviteShare', trackData);
									})
									.catch((e) => {
										// Ignore AbortErrors, they're expected if the user decides to not share
										if (!(e instanceof Error && e.name === 'AbortError')) {
											throw e;
										}
									});
							}}
						>
							Share
							<IconShare />
						</LinkButton>
					)}
				</Show>
				<LinkButton appearance="primary" class="font-medium" onClick={() => setShowRecent((p) => !p)}>
					Recent Players <IconCaretDownMd class={twJoin('transition-transform', showRecent() && 'rotate-180')} />
				</LinkButton>
			</div>

			<Show when={showRecent()}>
				<div>
					<RecentPlayers showRecentInvited={props.showRecentInvited} />
				</div>
			</Show>
		</div>
	);
}
