import { ActivityIndicator, Avatar, Button, Form, Tag } from '@troon/ui';
import { For, Suspense, Show, useContext, createMemo, Match, Switch } from 'solid-js';
import { createAsync, useSubmission } from '@solidjs/router';
import { twJoin } from '@troon/tailwind-preset/merge';
import { IconCircleCheck } from '@troon/icons/circle-check';
import {
	gql,
	mutationAction,
	ReservationUserState,
	TroonCardSubscriptionProductType,
	useMutation,
} from '../../../../graphql';
import { useUser } from '../../../../providers/user';
import { cachedQuery } from '../../../../graphql/cached-get';
import { ReservationCtx } from '../../../../providers/reservation';
import type { User } from '../../../../graphql';

type Props = {
	showRecentInvited?: boolean;
};

export function RecentPlayers(props: Props) {
	const user = useUser();
	const data = useContext(ReservationCtx)!;
	const users = createAsync(
		async () => await (data()?.reservation ? suggestedUsers({ reservationId: data()!.reservation.id }) : undefined),
	);

	const openSlotId = createMemo(() => {
		return data()
			?.reservation.users.filter((user) => user.state === ReservationUserState.Empty)
			.map(({ id }) => id)[0];
	});
	return (
		<Suspense
			fallback={
				<div class="flex h-32 flex-row justify-center self-center">
					<ActivityIndicator />
				</div>
			}
		>
			<h3 class="mb-4 text-xl font-semibold">Recent players</h3>
			<ul class="flex flex-col divide-y divide-neutral-500">
				<Show when={props.showRecentInvited}>
					<For each={data()?.reservation.users}>
						{(resUser) => (
							<Show when={resUser.user?.id !== user()?.me.id && resUser.user}>
								{(user) => (
									<li class="py-4">
										<Player reservationId={data()!.reservation.id} user={user()} invited />
									</li>
								)}
							</Show>
						)}
					</For>
				</Show>
				<For
					each={users.latest?.suggestedReservationUsers}
					fallback={
						<Show when={(data()?.reservation.users.length ?? 1) !== 1}>
							<li>No recent players yet. Please share the unique URL to your group.</li>
						</Show>
					}
				>
					{(user) => (
						<li class="py-4">
							<Player user={user} reservationId={data()!.reservation.id} slotId={openSlotId()} />
						</li>
					)}
				</For>
			</ul>
		</Suspense>
	);
}

type PlayerProps = {
	reservationId: string;
	slotId?: string;
	invited?: boolean;
	user: Pick<User, 'id' | 'firstName' | 'lastName' | 'troonAccessProductType'>;
};

function Player(props: PlayerProps) {
	const inviteUserAction = useMutation(handleInviteUser);
	const inviteUserSub = useSubmission(useMutation(handleInviteUser));

	return (
		<div class="flex items-center gap-x-4">
			<Avatar
				class={twJoin(
					'size-12 shrink-0 grow-0 rounded-full',
					props.invited ? 'bg-brand text-brand-100' : 'bg-neutral-700 text-neutral-100',
				)}
				firstName={props.user.firstName}
				lastName={props.user.lastName}
			/>
			<div class="flex shrink grow flex-col">
				<span class="flex flex-wrap gap-2">
					<span class="truncate">
						{props.user.firstName} {props.user.lastName}
					</span>
					<Show when={props.user?.troonAccessProductType}>
						{(type) => (
							<Tag appearance="access">{`Access${type() === TroonCardSubscriptionProductType.TroonAccessPlus ? '+' : ''}`}</Tag>
						)}
					</Show>
				</span>
			</div>
			<Switch>
				<Match when={props.invited}>
					<span class="flex items-center gap-1 px-3 text-sm text-green-600">
						<IconCircleCheck />
						Invited
					</span>
				</Match>
				<Match when={props.slotId}>
					<Form action={inviteUserAction} document={inviteMutation}>
						<input type="hidden" name="__reservationId" value={props.reservationId} />
						<input type="hidden" name="reservationUserId" value={props.slotId} />
						<input type="hidden" name="userId" value={props.user.id} />
						<Button
							disabled={inviteUserSub.pending}
							type="submit"
							class="shrink grow-0 font-normal normal-case"
							appearance="transparent"
							size="sm"
						>
							Invite
						</Button>
					</Form>
				</Match>
			</Switch>
		</div>
	);
}

const suggestedQuery = gql(`
query suggestedReservationUsers($reservationId: String!) {
  suggestedReservationUsers(reservationId: $reservationId) {
    id
    email
    firstName
    lastName
		troonAccessProductType
  }
}`);

const suggestedUsers = cachedQuery(suggestedQuery);

const inviteMutation = gql(`
mutation inviteUserToReservation($reservationUserId: String!, $userId: String!) {
  inviteUserToReservation(reservationUserId: $reservationUserId, userId: $userId) {
    id
  }
}`);

const handleInviteUser = mutationAction(inviteMutation, {
	revalidates: ['reservationInformation', 'suggestedReservationUsers'],
	toast: 'Invited user to reservation',
	track: {
		event: 'inviteUser',
		transform(data) {
			return { reservationId: data.get('__reservationId') as string, userId: data.get('userId') as string };
		},
	},
});
