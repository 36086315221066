import { createSignal } from 'solid-js';
import { Dialog, DialogTrigger } from '@troon/ui';
import { IconArrowRightMd } from '@troon/icons/arrow-right-md';
import { gql, TroonCardSubscriptionProductType } from '../../graphql';
import { createFragment } from '../../graphql/create-fragment';
import { UpsellAccessSection } from './access';
import { AccessProductDialogContent } from './dialog-content';
import type { FragmentType } from '../../graphql';

type Props = {
	upsell: FragmentType<typeof ReservationAccessUpsellFragment>;
};

export function ReservationAccessUpsell(props: Props) {
	const [showUpsellDialog, setShowUpsellDialog] = createSignal(false);
	const upsell = createFragment(ReservationAccessUpsellFragment, props, 'upsell');

	return (
		<UpsellAccessSection layout="compact" location="reservationConfirmation" withPhoto={false} class="cursor-pointer">
			<div class="absolute inset-0" onClick={() => setShowUpsellDialog(true)} />
			<p class="text-2xl font-semibold md:text-3xl">Save {upsell.savings.displayValue} on this tee time right now!</p>
			<p>Join Troon Access to save on this round and every round you play at over 150 courses.</p>
			<Dialog key="access-products" onOpenChange={setShowUpsellDialog} open={showUpsellDialog()}>
				<DialogTrigger class="relative z-10 size-fit">
					Save now <IconArrowRightMd />
				</DialogTrigger>
				<AccessProductDialogContent
					banners={{
						[TroonCardSubscriptionProductType.TroonAccess]: <>Save {upsell.savings.displayValue} on this tee time</>,
						[TroonCardSubscriptionProductType.TroonAccessPlus]: (
							<>Save {upsell.savings.displayValue} on this tee time</>
						),
					}}
					onCancel={() => setShowUpsellDialog(false)}
				/>
			</Dialog>
		</UpsellAccessSection>
	);
}

const ReservationAccessUpsellFragment = gql(`fragment ReservationAccessUpsell on TroonAccessReservationUpsell {
	savings { displayValue }
	displayMessage
}`);
