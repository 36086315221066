import {
	CheckList,
	CheckListItem,
	Dialog,
	DialogAction,
	DialogActions,
	DialogContent,
	DialogTrigger,
	LinkButton,
	Picture,
} from '@troon/ui';
import { IconInfo } from '@troon/icons/info';
import { createSignal, Show, Suspense, useContext } from 'solid-js';
import { useUser } from '../../../../providers/user';
import { ReservationCtx } from '../../../../providers/reservation';

export function InvitePlayersInfo() {
	const user = useUser();
	const data = useContext(ReservationCtx)!;
	const [showGuestPassDialog, setShowGuestPassDialog] = createSignal(false);
	return (
		<>
			<Show
				when={user()?.activeTroonCardSubscription?.unlimitedGuestPass}
				fallback={
					<>
						<p class="text-lg font-medium">Invite players to join your tee time to:</p>
						<CheckList>
							<Show when={user()?.me.troonAccessProductType}>
								<CheckListItem>
									<p>
										Share a Guest Pass{' '}
										<b class="text-nowrap">
											<Suspense>({data()?.guestPasses.available.length} remaining)</Suspense>
										</b>{' '}
										<Dialog
											key="how-to-share-guest-pass"
											open={showGuestPassDialog()}
											onOpenChange={setShowGuestPassDialog}
										>
											<DialogTrigger as={LinkButton} class="inline size-fit shrink grow-0 text-nowrap">
												How to share a Guest Pass <IconInfo />
											</DialogTrigger>
											<DialogContent header="How to share a Guest Pass" headerLevel="h3">
												<div class="flex flex-col gap-6">
													<Picture
														src="https://images.ctfassets.net/rdsy7xf5c8dt/4qaD5b85ngzu5gXIeA0uxF/4e9ec7d95b560cd285139c9e72f231f8/6cb4285b2cc8c907709509e6b00bb451.png"
														width={853}
														height={480}
														sizes="(min-width: 1024px) 853px, 95vw"
														alt="How to share a Guest Pass"
													/>
													<p>
														After booking a tee time, invite other players to join your reservation. Once they join, you
														can share a Guest Pass on the reservation detail page.
													</p>
												</div>

												<DialogActions>
													<DialogAction onClick={() => setShowGuestPassDialog(false)} type="button">
														Okay
													</DialogAction>
												</DialogActions>
											</DialogContent>
										</Dialog>
									</p>
								</CheckListItem>
							</Show>
							<CheckListItem>Make sure all Troon Access members get their discounted rate</CheckListItem>
							<CheckListItem>Streamline the check-in process</CheckListItem>
						</CheckList>
					</>
				}
			>
				<p class="text-lg">
					With your <b>Unlimited Guest Passes</b>, all players in your group will automatically receive your discounted{' '}
					<b>Troon Access+</b> rate. If you want to invite players to keep them in the loop on the tee time, you can do
					so below:
				</p>
			</Show>
		</>
	);
}
